import React from "react";
import "../../styles/about/imagesextion.css";
function HeaderImage() {
  return (
    <>
      <div className="about_page_headinig_image">
        <div className="about_us_header_text "
        data-aos="zoom-out"
        data-aos-offset="200"
        data-aos-delay="400"
        data-aos-duration="1200"

        >About US</div>
      </div>
    </>
  );
}

export default HeaderImage;
