import React from 'react'
import HeaderImage from '../components/about/headerImage'
import TheCompnay from '../components/about/theCompnay'
import RakarnisVision from '../components/about/RakarnisVision'

function About() {
  return (
    <div>
      <HeaderImage/>
      <TheCompnay/>
      <RakarnisVision/>
        


    </div>
  )
}

export default About